import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../layouts';
import { useSiteMetadata } from "../../hooks/use-site-metadata"

export default function PageNotFound() {
  
  const { title } = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>
          Page Not Found | {title}
        </title>
      </Helmet>
      <section className="section-body">
        <div className="content">
          <div className="content--main">
            <h1>404 page not found</h1>
            <p>
              Sorry about that. Come on over to our{' '}
              <Link to="/">homepage</Link> and get to know us a little better.
            </p>
            <Link to="/">
              <button>Home</button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
  
}

export const pageQuery = graphql`
  {
    wordpressAcfOptions {
      ...acfMetaDataQuery
    }
  }
`;
